<template>
  
  <div>
    
    
    <div v-if="!allProducts || allProducts.length === 0" class="text-center my-4">
      <div class="spinner-border text-dark" role="status">
        <!-- <span class="sr-only text-black">Loading...</span> -->
      </div>
      <p>Loading...</p>
    </div>

    <div v-else class="row">
      <div v-for="(item, index) in allProducts" :key="index" class="col-md-4 mb-4">
        <div 
          class="p-4 bg-light border rounded shadow-sm text-center cursor-pointer" 
          @click="toggleProducts(item)"
        >
          <h3 class="h5 font-weight-bold text-dark">{{ item.name }}</h3>
        </div>
      </div>
    </div>

    <!-- Display Products of Selected Item -->
    <div v-if="selectedItem" class="p-4 mt-4 bg-white border rounded shadow">
      <h2 class="h5 font-weight-bold text-dark">{{ selectedItem.name }} - Products</h2>

      <div v-if="selectedItem.products.length > 0" class="row">
        <!-- Product List -->
    <div v-for="(product, idx) in selectedItem.products" :key="idx" class="col-xl-4 col-lg-6 mb-3">
      <div
        class="p-3 bg-light border rounded text-center cursor-pointer"
        data-toggle="modal" data-target="#productModal"
        @click="openModal(product)"
      >
        {{ product.name }}
      </div>
      <div>
        <div class="mt-2">
          <stock-management-item 
            :key="product.id + '-' + idx" 
            :index="idx" 
            :product="product"
            @update-stock="updateStock" 
          />
        </div>
      </div>
    </div>

    <!-- Bootstrap Modal -->
    <div class="modal fade" id="productModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          
          <!-- Modal Header -->
          <div class="modal-header">
            <h5 class="modal-title">{{ selectedProduct ? selectedProduct.name : '' }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <!-- Modal Body -->
          <div class="modal-body">
            <!-- <p class="text-muted">Details about {{ selectedProduct ? selectedProduct.name : 'this product' }} go here...</p> -->

            <div v-if="selectedProduct && selectedProduct.addons && selectedProduct.addons.length">
              <div v-for="(addon, index) in selectedProduct.addons" :key="index" class="border p-3 mb-2 rounded text-center">
                <p class="text-black text-center font-weight-bold">{{ addon.modifier.description }}</p>
                  <!-- Row for Addon Products (max 3 per row) -->
                  <div class="row">
                    <div v-for="(addon_product, pIndex) in addon.addon_products" :key="pIndex" class="col-xl-6 mb-3">
                      <div class="p-3 bg-light border rounded text-center">
                        {{ addon_product.product.name }}
                        <div class="mt-2">
                          <stock-management-item 
                            :key="addon_product.product.id + '-' + pIndex" 
                            :index="pIndex" 
                            :product="addon_product.product"
                            @update-stock="updateStock" 
                          />
                        </div>
                      </div>
                    </div>
                  </div>

              </div>
            </div>
            <p v-else class="text-muted">No addons available.</p>
          </div>

          <!-- Modal Footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>

        </div>
      </div>
    </div>
      </div>
      <p v-else class="text-muted">No products available for this item.</p>
    </div>
      <h3 class="mt-3">Disabled Products</h3>
    <input type="text" class="form-control w-100 mb-2" placeholder="Search..." v-model="searchbar">
    <div class="table-responsive">
      <table class="table table-striped ">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Product Name</th>
          <th scope="col">SKU</th>
          <th scope="col">Available stock</th>
          <th scope="col">Default Quantity</th>
          <th scope="col">Is enabled</th>
          <th scope="col">New</th>
        </tr>
      </thead>
      <tbody v-if="search">
        <stock-management-data v-for="(stock, key) in search" :key="stock.id" :index="key" :product="stock"
          @update-stock="updateStock" />
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="7">No product found</td>
        </tr>
      </tbody>
    </table>
    </div>
    
  </div>
</template>

<script>
import mixins from '@/components/mixins/swal';
import { getStock, manageStock , changeStockQuantity} from '../../apis/Product';
import { mapGetters ,mapActions } from 'vuex';
import stockManagementData from './stockManagementData.vue';
import stockManagementItem from './stockManagementItem.vue';


export default {
  name: 'StockManagement',
  mixins: [mixins],
  components: {
    stockManagementData,
    stockManagementItem
  },
  data() {
    return {
      stocks: [],
      updatedStocks: [],
      searchbar: '',
      selectedItem: null,
      selectedProduct: null,
      
    };
  },
  computed: {
    ...mapGetters('user', ['getDetailUser']),
    ...mapGetters("product", ["allProducts"]),
    search() {
      if(this.searchbar == '') {
        // return this.stocks
        return this.stocks.filter(stock => stock.stock.is_enabled === 0);
      } else {
        return this.stocks.filter((stock)=>{
          return (stock.name).toLowerCase().includes(this.searchbar.toLowerCase())
        });
      }
    }
  },
  mounted() {
    this.fetchStock();
    this.getProducts();
  },
  methods: {
    ...mapActions('product', ['getProducts']),
    fetchStock() {
      const branchId = localStorage.getItem('branchId');
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        token: token,
      };
      getStock(requestData)
        .then((response) => {
          if (response.status) {
            this.stocks = response.data.data.products;
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },
    
    updateStock(changedStock) {
      const index = this.updatedStocks.findIndex(stock => stock.product_id === changedStock.product_id);
      // console.log('this is called',changedStock,index,this.updatedStocks)
      if (index !== -1) {
        this.updatedStocks.splice(index, 1, changedStock);
      } else {
        this.updatedStocks.push(changedStock);
      }
    },
    toggleProducts(item) {
      this.selectedItem = this.selectedItem && this.selectedItem.id === item.id ? null : item;
    },
    openModal(product) {
      this.selectedProduct = product;
    },

    saveAll() {
      const branchId = localStorage.getItem('branchId');
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        payload: this.updatedStocks,
        token: token,
      };

      manageStock(requestData)
        .then((response) => {
          // console.log(("response", response));
          if (response.status) {
            this.toastSuccess('All stocks saved successfully.');
            setTimeout(() => {
                window.location.reload();
            }, 1000);
          } else {
            this.toastError('Some stocks could not be saved.');
          }
        })
        .catch((error) => {
          console.log('Error', error);
          this.toastError('An error occurred while saving stocks.');
        });
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
