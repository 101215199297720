<template>
  <div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Code</th>
          <th scope="col">Type</th>
          <th scope="col">Status</th>
          <th scope="col">Platform</th>
          <th scope="col">Payment</th>
          <th scope="col">Discount</th>
          <th scope="col">Discount Type</th>
          <th scope="col">Total</th>
          <th scope="col">Sub Total</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody v-if="servedOrders.length > 0">
        <tr v-for="(order, index) in servedOrders" :key="index">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ order.code }}</td>
          <td>{{ order.type }}</td>
          <td>{{ order.status }}</td>
          <td>{{ order.platform }}</td>
          <td>{{ order.payment }}</td>
          <td>{{ order.discount }}</td>
          <td>{{ order.discount_type ? order.discount_type : 'none' }}</td>
          <td>{{ order.total }}</td>
          <td>{{ order.sub_total }}</td>
          <td>
            <b-button @click="showModal('Print', order.id)" v-b-modal.modal-primary variant="info" class="mr-2">{{
              order.reprint > 0 ? 'Reprint' : 'Print' }}</b-button>
               <b-button variant="danger" class="mr-2" :disabled="order.refund == 1"
               @click="refund(order.id)">Refund</b-button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="11">No orders found</td>
        </tr>
      </tbody>
    </table>

    <ReportModal :whatModal="getWhatModal" />
    <!-- <PrintOrder /> -->
  </div>
</template>

<script>
import mixins from '@/components/mixins/swal';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import PrintOrder from './PrintOrder.vue';
import ReportModal from './ReportModal.vue';
import { getOrder, PrintCounter , refundOrder } from '../../apis/Order';

export default {
  name: 'TableHistory',
  mixins: [mixins],
  components: {
    ReportModal,
    PrintOrder,
  },
  data() {
    return {
      orderDetails: null
    }
  },
  mounted() {
    this.fetchServedOrders();
  },
  computed: {
    ...mapGetters('history', ['getServedOrders']),
    ...mapGetters('modal', ['getWhatModal']),
    ...mapGetters('user', ['getDetailUser']),
    servedOrders() {
      return this.getServedOrders;
    },
  },
  methods: {
    ...mapMutations('modal', ['SET_WHAT_MODAL']),
    ...mapActions('history', ['fetchServedOrders','']),
    showModal(val, id) {
      const branchId = this.getDetailUser.data.branch.id;
      const token = localStorage.getItem('token');
      const requestData = {
        branchId: branchId,
        id: id,
        token: token,
      };

      getOrder(requestData)
        .then((response) => {
          if (response.status) {
            this.orderDetails = response.data.data;
            if (
              window.ReactNativeWebView &&
              window.ReactNativeWebView.postMessage
            ) {
              window.ReactNativeWebView.postMessage(JSON.stringify(response.data.data))
                this.printReceipt(requestData);
            } else {
              alert("print is not available")
            }
            // if(this.generateReceipt(response.data.data)) {
            // }
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });

      // this.SET_WHAT_MODAL(val);
    },

    printReceipt(requestData) {
      PrintCounter(requestData)
        .then((response) => {
          if (response.status) {
            this.fetchServedOrders();
            return true;
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },

    generateReceipt(data) {
      console.log("data",data)
      if (
          window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage
        ) {
          window.ReactNativeWebView.postMessage(data)
          return true;
        } else {
          alert("print is not available")
          return false;
        }
    },
    refund(id) {
      const branchId = this.getDetailUser.data.branch.id;
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        id: id,
        token: token,
      };
      refundOrder(requestData)
        .then((response) => {
          if (response.status) {
            this.toastSuccess('Order refunded successfully!');
            setTimeout(() => {
              window.location.reload();
            }, 1000); 
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },

  }
};
</script>
