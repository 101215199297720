<template>
  <div style="max-height: 100vh; overflow-y: auto; overflow-x: hidden">
    <b-card-group deck>
      <b-card isFooter noButtonHeader header="My Profile" header-tag="header">
        <div class="d-flex">
          <div class="mr-3"></div>
          <div class="detail">
            <p><b>Name:</b> {{ getDetailUser.data.name }}</p>
            <p><b>Email:</b> {{ getDetailUser.data.email }}</p>
            <p><b>Role:</b> {{ getDetailUser.data.roles[0].name }}</p>
            <p><b>Branch:</b> {{ getDetailUser.data.branch.name }}</p>
            <p><b>Phone:</b> {{ getDetailUser.data.branch.phone }}</p>
            <p><b>Address:</b> {{ getDetailUser.data.branch.address }}</p>
          </div>
        </div>
        <ButtonLogout classBtn="btn mr-2 my-1 btn-primary shadow">
          <span class="mr-2">Logout</span>
          <b-icon icon="box-arrow-in-left" scale="1" />
        </ButtonLogout>
      </b-card>

      <b-card header="POS configuration" header-tag="header">
        <div class="d-flex">
          <div class="mr-3"></div>
          <div class="detail">
            <b-form-group
              label="IP configuration"
              v-model="configSettings.ip"
              description="IP configuration of the pos machine"
            >
              <b-form-input v-model="configSettings.ip"></b-form-input>
            </b-form-group>
            <b-form-group label="Port" description="PORT configuration of the pos machine">
              <b-form-input v-model="configSettings.port"></b-form-input>
            </b-form-group>
            <b-button variant="info" @click="updateConfig">Save</b-button>
          </div>
        </div>
      </b-card>
    </b-card-group>
     <b-card-group class="mt-5">
      <b-card isFooter noButtonHeader header="Update Pin" header-tag="header">
        <div class="row">
          <div class="col-4">
            <div class="">
              <!-- <b-form-group  label="Phone Number" > -->
               <div class="">
                <b-form-input class="my-2" v-model="phoneNumber" placeholder="Enter phone number"></b-form-input>
                <b-form-input v-model="newPin" placeholder="Enter 4 Number Pin" minlength="4" maxlength="4" pattern="\d{4}" required></b-form-input>
               </div>
                <div class="d-flex justify-content-center mt-3"><b-button variant="primary" class="w-50" @click="setPinForPhone">Set</b-button></div>
              <!-- </b-form-group> -->
            </div>
          </div>

          
        </div>
      </b-card>
      <!-- <b-card isFooter noButtonHeader header="Update Pin" header-tag="header">
      
      </b-card> -->

    </b-card-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import mixins from '@/components/mixins/swal';
import ButtonLogout from '@/components/molecules/ButtonLogout';
import { getConfiguration, storeConfiguration, setPinForPhone } from '../../../apis/User';

export default {
  name: 'MyProfileCard',
  mixins: [mixins],
  components: {
    ButtonLogout,
  },
  data() {
    return {
      configSettings: {
        ip: null,
        port: null,
      },
      phoneNumber: '',
      newPin: null,
    };
  },
  mounted() {
    this.configuration();
  },
  computed: mapGetters('user', ['getDetailUser']),
  methods: {
    ...mapActions('user', ['posConfiguration']),
    configuration() {
      const branchId = localStorage.getItem('branchId');
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        token: token,
      };
      getConfiguration(requestData)
        .then((response) => {
          if (response.status) {
            this.posConfiguration(response.data.data);
            this.configSettings.ip = response.data.data.ip;
            this.configSettings.port = response.data.data.port;
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },
    updateConfig() {
      const branchId = localStorage.getItem('branchId');
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        pos_configuration: {
          ip: this.configSettings.ip,
          port: this.configSettings.port,
        },
        token: token,
      };

      storeConfiguration(requestData)
        .then((response) => {
          if (response.status) {
            this.configuration();
            this.toastSuccess('Configuration updated successfully!');
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },
    setPinForPhone() {
      console.log('123');
      const branchId = localStorage.getItem('branchId');
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        phone: this.phoneNumber,
        token: token,
        pin: this.newPin,
      };

      setPinForPhone(requestData)
      .then((response) => {
        console.log('user', response.data.data);
        if (response.status === 200) {
          this.toastSuccess('Pin updated for the phone number');
          setTimeout(() => {
            window.location.reload();
          }, 2000); 
        } else {
          console.log('error', response.message);
        }
      })
      .catch((error) => {
        console.log('Error', error);
          if (error.response && error.response.status === 404) {
            this.toastError('Phone number not found');
          } else {
            this.toastError('An unexpected error occurred');
          }
        });
        },
      },
  
};
</script>

<style scoped></style>
