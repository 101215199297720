<template>
  <div class="row">
    <div v-for="product in allProducts" :key="product.id">
      <div v-if="!showProduct">
        <CategoryItem
          :detailProduct="product"
          :checkActive="checkActive"
          @click.native="handleShowProducts"
        />
      </div>
    </div>
    <div v-if="showProduct">
      <ProductItem @goBack="handleGoBack" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CategoryItem from "@/components/molecules/CategoryItem";
import ProductItem from "@/components/molecules/ProductItem";

export default {
  data() {
    return {
      showProduct: false,
    };
  },
  methods: {
    handleShowProducts() {
      this.showProduct = true;
    },
    handleGoBack() {
      this.showProduct = false;
    },
  },
  components: {
    CategoryItem,
    ProductItem,
  },
  computed: {
    ...mapState("cart", ["carts"]),
    ...mapGetters('product', ['allProducts']),
    checkActive() {
      const carts = this.carts;
      return carts.map((cart) => {
        return cart.product.id;
      });
    },
  },
};
</script>

<style scoped></style>
