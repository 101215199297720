import Request from './ApiWithoutIinterceptor'
import Api from './Api'

export default {
  placeOrder(data) {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
    }

    if (data.token != null) {
      headers = {
        ...headers,
        Authorization: `Bearer ${data.token}`,
      }
    }

    const config = {
      headers,
      skipInterceptor: true,
    }

    return Request.post(
      `${data.branchId}/order/place-order`,
      data.payload,
      config
    )
  },
}

export async function cancelOrder(data) {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  }

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    }
  }

  const config = {
    headers,
  }

  return Api.post(`${data.branchId}/order/cancel-order`, data, config)
}
export async function readyOrder(data) {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  }

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    }
  }

  const config = {
    headers,
  }

  return Api.post(`${data.branchId}/order/ready-order`, data, config)
}
export async function serveOrder(data) {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  }

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    }
  }

  const config = {
    headers,
  }

  return Api.post(`${data.branchId}/order/serve-order`, data, config)
}

export async function sendReadyOrderReminder(data) {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  }

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    }
  }

  const config = {
    headers,
  }

  return Api.post(
    `${data.branchId}/order/send-ready-order-reminder`,
    data,
    config
  )
}

export async function refundOrder(data) {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  }

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    }
  }

  const config = {
    headers,
  }

  return Api.post(`${data.branchId}/order/refund-order`, data, config)
}



export async function getOrder(data) {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  }

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    }
  }

  const config = {
    headers,
  }

  return Api.post(`${data.branchId}/order/get-order-by-id`, data, config)
}

export async function PrintCounter(data) {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  }

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    }
  }

  const config = {
    headers,
  }

  return Api.post(`${data.branchId}/order/print-counter`, data, config)
}

// export async function RefundOrder(data) {
// console.log('its called');
//   let headers = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//   };

//   if (data.token) {
//     headers.Authorization = `Bearer ${data.token}`;
//   }

//   const config = { headers };

//   // return Api.post(`${data.branchId}/order/refund`, data, config);
// }