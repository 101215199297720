const state = {
    socketStatus: "disconnected", // Default status
  };
  
  const mutations = {
    SET_SOCKET_STATUS(state, status) {
      state.socketStatus = status;
    },
  };
  
  const actions = {
    updateSocketStatus({ commit }, status) {
      commit("SET_SOCKET_STATUS", status);
    },
  };
  
  const getters = {
    getSocketStatus: (state) => state.socketStatus,
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  