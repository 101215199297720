<template>
  <div class="stock-item d-flex align-items-center mb-2 border py-2">
    <!-- <span class="mr-3">{{ index + 1 }}. {{ product.name }} ({{ product.sku }})</span> -->
    <div>
      <label for="availableStock" class="font-weight-bold">Available</label>
    <input type="number" min="0" v-model="available_stock" @input="updateChanges" class="form-control mr-2 w-100"
      placeholder="Available Stock" required />
    </div>

    <div>
      <label for="availableStock" class="font-weight-bold">Default</label>
      <input type="number" min="0" v-model="default_quantity" @input="updateChanges" class="form-control mr-2 w-100"
      placeholder="Default Quantity" required />
    </div>

    <div>
      <label for="availableStock" class="font-weight-bold">Is enabled</label>
      <div class="btn-group btn-group-toggle mr-2">
        <label class="btn btn-sm" :class="[is_enabled == 1 ? 'btn-success' : 'btn-secondary']">
          <input type="radio" v-model="is_enabled" @change="updateChanges" value="1" /> Yes
        </label>
        <label class="btn btn-sm" :class="[is_enabled == 0 ? 'btn-success' : 'btn-secondary']">
          <input type="radio" v-model="is_enabled" @change="updateChanges" value="0" /> No
        </label>
      </div>
    </div>

    <div>
      <label for="availableStock" class="font-weight-bold">New</label>
        <div class="btn-group btn-group-toggle">
          <label class="btn btn-sm" :class="[is_new == 1 ? 'btn-success' : 'btn-secondary']">
            <input type="radio" v-model="is_new" @change="updateChanges" value="1" /> Yes
          </label>
          <label class="btn btn-sm" :class="[is_new == 0 ? 'btn-success' : 'btn-secondary']">
            <input type="radio" v-model="is_new" @change="updateChanges" value="0" /> No
          </label>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['index', 'product'],
  data() {
    return {
      available_stock: this.product.stock ? this.product.stock.available_stock : 0,
      default_quantity: this.product.stock ? this.product.stock.default_quantity : 0,
      is_enabled: this.product.stock ? this.product.stock.is_enabled : 0,
      is_new: this.product.stock ? this.product.stock.is_new : 0,
    };
  },
  methods: {
    updateChanges() {
      this.$emit('update-stock', {
        product_id: this.product.id,
        available_stock: this.available_stock,
        default_quantity: this.default_quantity,
        is_enabled: this.is_enabled,
        is_new: this.is_new,
      });
    },
  },
};
</script>

<style scoped>
.stock-item {
  display: flex;
  gap: 10px;
}
</style>
