import Vue from "vue";
import VueToast from "vue-toast-notification";
import VueLazyload from "vue-lazyload";
import VueCurrencyFilter from "vue-currency-filter";
import VueSweetalert2 from "vue-sweetalert2";
import VueCryptojs from "vue-cryptojs";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueHtmlToPaper from "vue-html-to-paper";
import "@/globalComponents.js";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-toast-notification/dist/theme-default.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-search-select/dist/VueSearchSelect.css";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

const options = {
  styles: ["https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"],
};

// BootstrapVue & Other Plugins
Vue.use(BootstrapVue);
Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
});
Vue.use(VueCryptojs);
Vue.use(VueSweetalert2);
Vue.use(VueLazyload);
Vue.use(VueToast, {
  position: "bottom-left",
});
Vue.use(IconsPlugin);
Vue.use(VueHtmlToPaper, options);

// Custom Directive
Vue.directive("focus", {
  inserted: (el) => {
    el.focus();
  },
});

Vue.config.productionTip = false;

// Initialize WebSocket (Laravel Echo)
window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: "pusher",
  key: "095f520b-dfa8-42f2-8388-ea039f7a0e9b",
  wsHost: "admin.funwan.sg",
  wssPort: 6001,
  cluster: "ap1",
  encrypted: true,
  useTLS: false,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
});

// Update Vuex Store on WebSocket Events
window.Echo.connector.pusher.connection.bind("connected", () => {
  store.dispatch("socket/updateSocketStatus", "connected");
});

window.Echo.connector.pusher.connection.bind("disconnected", () => {
  store.dispatch("socket/updateSocketStatus", "disconnected");
});

window.Echo.connector.pusher.connection.bind("error", () => {
  store.dispatch("socket/updateSocketStatus", "disconnected");
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
